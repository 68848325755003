import './App.css';
// import AForm from './components/AForm';
import Footer from './components/Footer';
import { Switch, Route } from 'react-router-dom';
import React from 'react';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path='/'>
          <div style={{backgroundColor: "#41306F", padding:"2% 8%", boxShadow: "10px 10px 5px #ffff", display: "flex", alignItems: "center", flexFlow: "column wrap"}}>
            <div style={{textAlign: "left", width: "100%"}}>
              <img alt="bgsw presents" style={{width:"10em", paddingBottom: "20px", alignSelf: "left"}} src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/2150431699/settings_images/q2UQgJ4QTLe1dAyTIBrp_bgsw_presents_center.png"/>
            </div>
            <div style={{flexGrow: "8", width: "85%"}}>
              <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0 0",position:"relative"}}><div class="wistia_responsive_wrapper" style={{height:"100%",left:"0",position:"absolute",top:"0",width:"100%"}}><iframe src="https://fast.wistia.net/embed/iframe/pmneug3ucz?videoFoam=true" title="MT ad Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
              <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
              <h2 style={{textAlign: "left", color: "#41306F", fontSize: "11px"}}>Free Speaking Course Intro Video</h2>
            </div>
            {/* <h1 style={{color: "white"}}>Take your Free Speaker Assessment to unlock the<br/>Free Speaking Course:</h1>*/}
            {/* <button data-tf-popup="GXY9zWGs" data-tf-iframe-props="title=Speaker Assessment" data-tf-medium="snippet" style={{all:"unset",fontFamily:"Helvetica,Arial,sans-serif",display:"inline-block",maxWidth:"100%",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",backgroundColor:"#0445AF",color:"#FFFFFF",fontSize:"20px",borderRadius:"25px",padding:"0 33px",fontWeight:"bold",height:"50px",cursor:"pointer",lineHeight:"50px",textAlign:"center",margin:"0",textDecoration:"none"}}>Speaker Assessment</button><script src="//embed.typeform.com/next/embed.js"></script> */}
            {/* <a href="https://3oujudwxi99.typeform.com/speaker" style={{backgroundColor:"#955834", color:"white", padding:"15px 40px 20px", fontSize:"30px", borderRadius:"20px", marginBottom: "90px"}}>Speaker Assessment</a>*/}
            <h2 style={{color: "white"}}>"Learn Professional-Level Keynote Speaking Skills and Get Paid to Build Your Brand, Change Lives and Leave a Legacy"</h2>
          </div>
          <div style={{backgroundImage: "url(https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/3210473/settings_images/B7sLNq4iSpC7ZoY2zrZO_steve_bg.jpg)", backgroundRepeat: 'no-repeat', backgroundAttachment: "fixed", color: "white", backgroundSize: "cover", padding: "20px 0 50px 0"}}>
            <div style={{width: "80%", maxWidth: "1080px", margin: "auto"}}>
              <h1 style={{padding: "20px"}}>Here's What You Will Learn</h1>
              <ul style={{textAlign: "left", width: "70%", margin: "auto"}}>
                <li>How to Select Your Topic</li>
                <li>10 Topics that companies and organizations will pay to hear</li>
                <li>Who pays speakers and how to contact them</li>
                <li>The highest paid form of public speaking</li>
                <li>How to set your speaking fees</li>
                <li>The key skills you’ll need to get booked</li>
                <li style={{listStyle: "none", paddingLeft: "20px"}}>And much more…</li>
              </ul>
            </div>
          </div>
          <div style={{backgroundColor: "#41306F", color: "white", padding: "20px 0 70px 0"}}>
            <div style={{width: "80%", maxWidth: "1080px", margin: "auto", textAlign: "center"}}>
              <h2 style={{padding: "20px"}}>Powerful Public Speaking Can Catapult Your Confidence And Thrust You Into A Position Of Leadership And Authority</h2>
              <h1 style={{padding: "5px"}}>BGSW Online</h1>
              <div style={{display: "flex", flexFlow: "row"}}>
                <div style={{flexFlow: "column nowrap", justifyContent: "center"}}>
                  <img style={{width: "60%"}} alt="bgsw" src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/2469901/settings_images/nLNbWupQriL0ZrlPFHeQ_Bill_Gove_Speech_Workshop_Gator_Board1.png" />
                  <h3>SAVE $400<br/>LIFETIME Access Pay in Full $1997</h3>
                  <a href="https://www.ipdar.org/offers/zuT2gXrz" style={{backgroundColor:"#E06736", color:"white", padding:"8px 20px 10px", fontSize:"28px", borderRadius:"10px", margin: "20px 0 130px 0"}}>I'M READY!</a>
            </div>
                <div style={{flexFlow: "column nowrap", justifyContent: "center"}}>
                  <img style={{width: "60%"}} alt="bgsw" src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/2469901/settings_images/nLNbWupQriL0ZrlPFHeQ_Bill_Gove_Speech_Workshop_Gator_Board1.png" />
                  <h3>6 Installments at $397<br/>Get STARTED TODAY for just $397!</h3>
                  <a href="https://www.ipdar.org/offers/GtZKjGhg" style={{backgroundColor:"#E06736", color:"white", padding:"8px 20px 10px", fontSize:"28px", borderRadius:"10px", margin: "20px 0 130px 0"}}>I'M READY!</a>
                </div>
              </div>
            </div>
          </div>
          <div style={{display: "flex", width: "80%", maxWidth: "1080px", margin: "auto", padding: "4%", justifyContent: "space-around", alignItems: "center"}}>
              <div style={{width: "20%", alignSelf: "flex-end"}}>
                <h1 style={{textAlign: "right"}}>About Steve Siebold</h1>
                <img style={{width: "150%", marginTop: "20px"}} alt="steve" src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/3210473/settings_images/jULbSCq6TNyx1EYmvx0K_steve-desk-SM.jpg"/>
              </div>
              <div style={{width: "40%"}}><p style={{textAlign: "left"}}>Your instructor will be Steve Siebold, Certified Speaking Professional and Past Chairman of the National Speaker’s Associations Million Dollar Speakers Group. Since 1997, Steve has delivered $16 million dollars in keynote speeches at national and international conventions around the world.</p></div>            
          </div>
          <div style={{width: "80%", margin: "auto", paddingBottom: "50px"}}>
            <h2>Here's what some of the biggest names in the business have to say about Steve Siebold:</h2>
            <p>“Steve Siebold was the best student Bill Gove ever produced. His knowledge of keynote speaking has no equal.” <br/><strong>–Larry Wilson, founder, Wilson Learning and Speakers Hall of Fame</strong></p>
            <p>“Bill Gove was the father of professional speaking and the Frank Sinatra of speakers. Steve Siebold was his protégé, and he can teach you more about public speaking than anyone alive.” <br/><strong>–Bob Proctor, a Professional Speaker and Star of “The Secret”</strong></p>
            <p>“Steve Siebold is the best public speaking teacher in the world, bar none. He is the lone creator of generations of knowledge on the subject.” <br/><strong>–Doug Wead, White House Senior Advisor to President Ronald Reagan.</strong></p>
          </div>
          <Footer bkgColor={"#41306F"}/>
        </Route>

        <Route path='/thank-you'>
          <div style={{backgroundColor: "#41306F", padding:"3% 10%", boxShadow: "10px 10px 5px #ffff", color: "white", textAlign: "left"}}>
            <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0% 0",position:"relative",marginBottom:"0px"}}><div class="wistia_responsive_wrapper" style={{height:"100%",left:"0",position:"absolute",top:"0",width:"100%"}}><iframe src="https://fast.wistia.net/embed/iframe/pmneug3ucz?videoFoam=true" title="FSC Full Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
            <a style={{paddingTop: "30px", display: "flex", justifyContent:"center", alignItems:"center"}} target="_blank" rel="noopener noreferrer" href="/bgsw">
              <img alt="bgsw" style={{width:"25%", paddingRight: "15px"}} src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/2469901/settings_images/nLNbWupQriL0ZrlPFHeQ_Bill_Gove_Speech_Workshop_Gator_Board1.png"/>
              <h1 style={{fontWeight: "lighter"}}>Click here to learn more <br/>about the Bill Gove Speech Workshop.</h1>
            </a>
          </div>
          <Footer bkgColor={"#41306F"}/>
        </Route>

        <Route path='/bgsw'>
          <div style={{backgroundColor: "#33308B", padding:"3% 10%", boxShadow: "10px 10px 5px #ffff", color: "white", textAlign: "left"}}>
            <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0% 0",position:"relative",marginBottom:"0px"}}><div class="wistia_responsive_wrapper" style={{height:"100%",left:"0",position:"absolute",top:"0",width:"100%"}}><iframe src="https://fast.wistia.net/embed/iframe/l93hksoq7h?videoFoam=true" title="BGSW Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
            <div style={{display:"flex", justifyContent: "space-around", paddingTop: "30px"}}>
              {/* <a style={{width: "40%"}} target="_blank" rel="noopener noreferrer" href="/bgsw-reduced-offer">
                <button style={{width: "100%", height:"70px", borderRadius:"20px", backgroundColor: "#F15D22", border: "none", cursor: "pointer"}}><h1 style={{margin: "0"}}>No, thanks.</h1></button>
              </a> */}
              <a style={{width: "40%"}} href="https://www.ipdar.org/offers/zFpo6B2x/checkout">
                <button style={{width: "100%", height:"70px", borderRadius:"20px", backgroundColor: "#F15D22", border: "none", cursor: "pointer"}}><h1 style={{margin: "0"}}>YES, Sign Me Up!</h1></button>
              </a>
            </div>
          </div>
          <Footer bkgColor={"#33308B"}/>
        </Route>

        <Route path='/bgsw-live-offer'>
          <div style={{backgroundColor: "#33308B", padding:"3% 10%", boxShadow: "10px 10px 5px #ffff", color: "white", textAlign: "left"}}>
            <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0% 0",position:"relative",marginBottom:"0px"}}><div class="wistia_responsive_wrapper" style={{height:"100%",left:"0",position:"absolute",top:"0",width:"100%"}}><iframe src="https://fast.wistia.net/embed/iframe/p58k3utq9h?videoFoam=true" title="BGSW Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
            <div style={{display:"flex", justifyContent: "space-around", paddingTop: "30px"}}>
              <a style={{width: "40%"}} href="https://www.authorspeakeracademy.com/offers/62LVUcQV">
                <button style={{width: "100%", height:"120px", borderRadius:"20px", backgroundColor: "#F15D22", border: "none", cursor: "pointer"}}><h1 style={{margin: "0", padding: "0 5px"}}>YES, I Want To Take Advantage of this Exclusive Offer!</h1></button>
              </a>
              <a style={{width: "40%"}} href="https://www.authorspeakeracademy.com/thank-you-bgsw0">
                <button style={{width: "100%", height:"70px", borderRadius:"20px", backgroundColor: "#F15D22", border: "none", cursor: "pointer"}}><h1 style={{margin: "0"}}>No, Take Me to BGSW Online</h1></button>
              </a>
            </div>
          </div>
          <Footer bkgColor={"#33308B"}/>
        </Route>

        <Route path='/bgsw-reduced-offer'>
          <div style={{backgroundColor: "#33308B", padding:"3% 10%", boxShadow: "10px 10px 5px #ffff", color: "white", textAlign: "left"}}>
            <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0% 0",position:"relative",marginBottom:"0px"}}><div class="wistia_responsive_wrapper" style={{height:"100%",left:"0",position:"absolute",top:"0",width:"100%"}}><iframe src="https://fast.wistia.net/embed/iframe/wwm0k8hp2s?videoFoam=true" title="BGSW Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
            <div style={{display:"flex", justifyContent: "space-around", paddingTop: "30px"}}>
              <a style={{width: "40%"}} href="https://www.authorspeakeracademy.com/offers/83CPsVSo/checkout">
                <button style={{width: "100%", height:"70px", borderRadius:"20px", backgroundColor: "#F15D22", border: "none", cursor: "pointer"}}><h1 style={{margin: "0"}}>YES, I Want To Try the BGSW for Three Months!</h1></button>
              </a>
              {/* <a style={{width: "40%"}} target="_blank" rel="noopener noreferrer" href="https://www.authorspeakeracademy.com/offers/FB7kYLL5">
                <button style={{width: "100%", height:"70px", borderRadius:"20px", backgroundColor: "#F15D22", border: "none"}}><h1 style={{margin: "0"}}>No, Take Me to BGSW Online</h1></button>
              </a> */}
            </div>
          </div>
          <Footer bkgColor={"#33308B"}/>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
